import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {sluger} from "../util/sluger"

const PostCard = ({ data }) => (
  <article className="post-card">
    {data.frontmatter.featuredImage ? 
      (
        <div className="image-box">
        <Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }>
          <Img 
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid} 
            //fluid={{ ...data.frontmatter.featuredImage.childImageSharp.fluid, aspectRatio: 21 / 9 }}
            objectFit="cover"
           // objectFit="contain"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + ' - Featured image'}
            className="featured-image"
          />
        </Link>
        </div>
      ) : ""
    }
    <div className="post-content">
      <h2 className="title"><Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }>{data.frontmatter.title}</Link></h2>
      <p className="meta"><time>{data.frontmatter.date}</time></p>
      <Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }><p className="description">{data.excerpt}</p></Link>
    </div>
 
  </article>
)

export default PostCard